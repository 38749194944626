import { Payments } from '../../services';
import { createRestSlices, applyReducers } from './rest';
import { getBalance, getFinancialHistory } from './transactions';

const {
    initialState,
    startCreateReducer, createReducer, getReducer, listReducer,
    updateReducer, deleteReducer,
    startCreateAction, createAction, getAction, listAction,
    updateAction, deleteAction
} = createRestSlices(Payments);

initialState.isAskingPayment            = false;
initialState.hasAskingPaymentError      = false;
initialState.askingPaymentErrorMessage  = null;
initialState.isFixingBalances           = false;
initialState.hasFixingBalancesError     = false;
initialState.fixingBalancesErrorMessage = null;

/* Export CRUD actions */
export const startCreatePayment = startCreateAction;
export const createPayment      = createAction;
export const loadPayment        = getAction;
export const loadPayments       = listAction;
export const updatePayment      = updateAction;
export const deletePayment      = deleteAction;

const RESET_ASKING_PAYMENT   = 'pixoo/Payments/RESET_ASKING_PAYMENT';
const ASKING_PAYMENT         = 'pixoo/Payments/ASKING_PAYMENT';
const ASKING_PAYMENT_SUCCESS = 'pixoo/Payments/ASKING_PAYMENT_SUCCESS';
const ASKING_PAYMENT_FAILURE = 'pixoo/Payments/ASKING_PAYMENT_FAILURE';

/* askingPayment reducer */
const askingPaymentReducer = (state, action) => {
    switch (action.type) {
        case RESET_ASKING_PAYMENT:
            return {
                ...state,
                isAskingPayment: false,
                askedPayment: null,
                hasAskingPaymentError: false
            };
        case ASKING_PAYMENT:
            return {
                ...state,
                isAskingPayment: true,
                askedPayment: null,
                hasAskingPaymentError: false
            };
        case ASKING_PAYMENT_SUCCESS:
            return {
                ...state,
                isAskingPayment: false,
                askedPayment: action.payment,
                hasAskingPaymentError: false
            };
        case ASKING_PAYMENT_FAILURE:
            return {
                ...state,
                isAskingPayment: false,
                askedPayment: null,
                hasAskingPaymentError: true,
                askingPaymentErrorMessage: action.error.message
            };
        default:
            return state;
    }
};

const RESET_FIXING_BALANCES   = 'pixoo/Payments/RESET_FIXING_BALANCES';
const FIXING_BALANCES         = 'pixoo/Payments/FIXING_BALANCES';
const FIXING_BALANCES_SUCCESS = 'pixoo/Payments/FIXING_BALANCES_SUCCESS';
const FIXING_BALANCES_FAILURE = 'pixoo/Payments/FIXING_BALANCES_FAILURE';

/* fixingBalances reducer */
const fixingBalancesReducer = (state, action) => {
    switch (action.type) {
        case RESET_FIXING_BALANCES:
            return {
                ...state,
                isFixingBalances: false,
                hasFixingBalancesError: false,
                fixingBalancesErrorMessage: null,
            };
        case FIXING_BALANCES:
            return {
                ...state,
                isFixingBalances: true,
                hasFixingBalancesError: false,
                fixingBalancesErrorMessage: null,
            };
        case FIXING_BALANCES_SUCCESS:
            return {
                ...state,
                isFixingBalances: false,
                hasFixingBalancesError: false,
                fixingBalancesErrorMessage: null,
            };
        case FIXING_BALANCES_FAILURE:
            return {
                ...state,
                isFixingBalances: false,
                hasFixingBalancesError: true,
                fixingBalancesErrorMessage: action.error.message,
            };
        default:
            return state;
    }
};

function askingPayment() { return { type: ASKING_PAYMENT } }
function askingPaymentSuccess(payment) { return { type: ASKING_PAYMENT_SUCCESS, payment } }
function askingPaymentFailure(err) { return { type: ASKING_PAYMENT_FAILURE, error: err } }
const askPaymentAction = (accountId, filters, refresh) => {
    if (!accountId)
        throw new Error("Missing account id");

    return (dispatch) => {
        dispatch(askingPayment());
        Payments.ask(accountId, filters)
            .then(data => {
                dispatch(askingPaymentSuccess(data.payment));
                if (refresh) {
                    dispatch(getBalance({'account_id': accountId}));
                    dispatch(getFinancialHistory({'account_id': accountId}));
                }
            })
            .catch(err => {
                dispatch(askingPaymentFailure(err))
            });

    }
}

function resetAskingPayment() { return { type: RESET_ASKING_PAYMENT } }
const resetAskPaymentAction = () => {
    return (dispatch) => {
        dispatch(resetAskingPayment());
    }
}

function fixingBalances() { return { type: FIXING_BALANCES } }
function fixingBalancesSuccess() { return { type: FIXING_BALANCES_SUCCESS } }
function fixingBalancesFailure(err) { return { type: FIXING_BALANCES_FAILURE, error: err } }
const fixBalancesAction = (accountId, refresh) => {
    return dispatch => {
        dispatch(fixingBalances());
        Payments.fixBalances(accountId)
            .then(data => {
                dispatch(fixingBalancesSuccess(data.payment));
                if (refresh) {
                    dispatch(getBalance({'account_id': accountId}));
                    dispatch(getFinancialHistory({'account_id': accountId}));
                }
            })
            .catch(err => {
                dispatch(fixingBalancesFailure(err))
            });

    }
}

function resetFixingBalances() { return { type: RESET_FIXING_BALANCES } }
const resetFixingBalancesAction = () => {
    return dispatch => {
        dispatch(resetFixingBalances());
    }
}

/* Export reducer */
export default (state = initialState, action) => {
    return applyReducers(state, action, [
        startCreateReducer, createReducer,
        getReducer, listReducer,
        updateReducer, deleteReducer,
        askingPaymentReducer, fixingBalancesReducer
    ]);
}

export {
    askPaymentAction,
    resetAskPaymentAction,
    fixBalancesAction,
    resetFixingBalancesAction
};
